<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="pb-8"
      >
        <div class="page-title text-center px-7 pt-3" v-if="claims.length > 0">
          <h2
            class="font-weight-semibold text--primary d-flex align-center justify-center mb-2 pb-2">
            <span>{{ claimHeader }}</span>
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          v-if="!claimsLoaded"
          class="mx-auto mt-0 pt-0"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
        <div
          v-if="claims.length > 0"
          class="cards-wrapper"
          :class="{ 'closed': !startClaim, 'opened': startClaim, 'mobile': isMobile}"
          @click="openPack"
        >
          <div class="cards-box">
            <v-row>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
              >
                <div
                  v-if="fighterClaims.length > 0"
                  class="card fighter"
                >
                  <div
                    class="ml-7 mt-3 v-card v-sheet v-sheet--outlined theme--dark px-5  py-4 mr-8  glow-primary"
                    :class="{ 'glow': !coinClaimed, 'active': !coinClaimed }"
                  >
                    <h2
                      v-if="!fighterClaimed"
                      class="text-center"
                    >
                      Fighter Pack
                    </h2>
                    <div class="row">
                      <div class="col-md-12">
                        <v-img
                          v-if="!fighterClaimed"
                          :src="fighterImage"
                          alt="upgrade-banner"
                          transition="scale-transition"
                          class="img-responsive text-center mx-auto mt-4 mb-0 pb-0"
                          max-width="120"
                        ></v-img>
                      </div>
                      <div class="col-md-12">
                        <div class="animate">
                          <FighterCard
                            v-if="fighterClaimed"
                            :fighter="rankedFighter"
                          ></FighterCard>
                        </div>
                        <p
                          v-if="!fighterClaimed"
                          class="mt-3 text-center mb-1"
                        >
                          Receive 1 ranked fighter.
                        </p>

                        <div
                          v-if="fighterClaiming"
                          class="text-center text-xs col-12 mb-0 pb-0"
                        >
                          <span>Status: {{ NFTstatus }}</span>
                        </div>

                        <p
                          v-if="fighterClaimed"
                          class="mt-10 text-center mb-0"
                        >
                          <v-icon
                            color="success"
                          >
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          Successfully recruited <span
                          class="font-weight-bold">{{ rankedFighter.name }}</span> to
                          your team!
                        </p>
                        <v-flex
                          class="text-xs-center align-center"
                          :class="{ 'ml-11': !fighterClaimed }"
                        >
                          <v-btn
                            :loading="fighterClaiming"
                            :disabled="fighterClaimed"
                            color="primary"
                            class="mt-7 ml-4 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
                            :class="{ 'ml-11 ': fighterClaimed }"
                            @click="fighterClaim()"
                          >
                            <v-icon
                              v-if="fighterClaimed"
                              color="success"
                              class="mr-2"
                            >
                              {{ icons.mdiCheckCircleOutline }}
                            </v-icon>
                            {{ fighterClaimText }}
                          </v-btn>
                          <v-btn
                            v-if="fighterClaimed"
                            class="ml-2 mt-7"
                            color="primary"
                            @click="hideFighter"
                          >
                            Next
                          </v-btn>
                        </v-flex>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <div v-if="coinClaims.length > 0 && !allcoinsClaims">
                <v-col
                  v-for="(item, index) in coinClaims"
                  :key="index"
                  cols="12"
                  md="12"
                  class="pb-12"
                >
                  <div

                    class="card coins"
                  >
                    <div
                      class="ml-7 mt-3 v-card v-sheet v-sheet--outlined theme--dark px-5 py-4 mr-8 glow-warning"
                      :class="{ 'glow': !item.claimed, 'active': !startClaim }"
                    >
                      <h2 class="text-center mb-2">
                        Silver KombatCoins
                      </h2>
                      <div class="row">
                        <div class="col-md-12">
                          <v-img
                            :src="require(`@/assets/images/avatars/KombatCoinDW.png`)"
                            alt="KombatCoins"
                            transition="scale-transition"
                            class="img-responsive text-center mx-auto img-rounded"
                            max-width="150"
                            contain
                          ></v-img>
                        </div>
                      </div>
                      <div class="mt-3 pt-1 mb-0 pb-0 text-center">
                        <span v-if="item.from" class="font-weight-semibold mt-4">{{ item.from }} sent
                          you a
                          {{ item.rewardType }}!</span>
                        {{ item.event }}<br>
                        {{ item.desc }}
                      </div>
                      <div
                        class="col-9 offset-1"
                      >
                        <div class="row">
                          <div
                            v-if="!item.claimed"
                            class="col-md-3 col-xs-3 col-sm-3 offset-md-1 offset-1 col-4"
                          >
                            <v-img
                              :src="require(`@/assets/images/buy/KombatCoinSilver.png`)"
                              alt="upgrade-banner"
                              transition="scale-transition"
                              class="float-left mb-0 pt-0 mr-0 ml-6"
                              :class="{ 'cardSpinner': coinClaiming }"
                              max-width="34"
                            ></v-img>
                          </div>
                          <div class="col-md-8 col-xs-8 col-sm-8 col-7 mb-0 pb-0">
                            <span
                              v-if="!item.claimed"
                              id="countDown"
                              class="text-2xl font-weight-semibold text-center ml-1 pt-2 mb-0 pb-0"
                            >{{ item.silverCoins|toCurrencyNoDec }}</span>
                          </div>
                          <p
                            v-if="item.claimed"
                            class="text-success text-center mt-0 pt-0 col-12"
                          >
                            <v-icon
                              color="success"
                              class="mr-1"
                            >
                              {{ icons.mdiCheckCircleOutline }}
                            </v-icon>
                            Claimed
                          </p>

                          <div
                            v-if="coinClaiming"
                            class="text-center text-xs col-12"
                          >
                            Minting KombatCoins..
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <v-btn
                          v-if="!item.claimed"
                          class="mt-1 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"
                          outlined
                          color="success"
                          :loading="coinClaiming"
                          @click="claimCoins(item, index)"
                        >
                          {{ coinClaimText }}
                        </v-btn>
                        <span v-if="index == 0">
                          <v-btn
                            v-if="item.claimed"
                            class="mt-1"
                            outlined
                            @click="allcoinsClaims = true"
                          >
                            Next
                          </v-btn>
                        </span>
                        <span v-else>
                          <v-btn
                            v-if="item.claimed"
                            outlined
                            class="text-center mt-1"
                            @click="removeCard(index)"
                          >
                            Next
                          </v-btn>
                        </span>
                        <br>
                        <p
                          v-if="!item.claimed"
                          class="text-sm mt-3"
                        >
                          Prizes left to claim: {{ coinClaims.length }}
                        </p>
                        <p v-else>
                          <br>
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
              </div>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
                :class="{ 'open-pack ': startClaim }"
              >
                <div class="card topcard plastic-wrap">
                  <div
                    v-if="!startClaim"
                    class="ribbon ribbon-top-right"
                  >
                    <span>Open here</span>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="pb-12"
                :class="{ 'open-pack ': startClaim }"
              >
                <div class="card topcard cover">
                  <h1 class="text-center">
                    FantasyKombat
                    <br>
                    Prize pack
                  </h1>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else-if="claimsLoaded " class="mx-auto text-center">
          <v-card class="ma-6 pb-5">
            <div v-if="startClaim">
              <div
                class="mt-2 mb-4"
                :class="{ 'active': !allcoinsClaims }"
              >
                <h2 class="text-center mt-4 pt-6">
                  Fighter Valuation
                </h2>
                <p class="mt-5 opacity-1">
                  Based on recent results, some of your fighters may have increased in value!
                  <br>
                  <br>
                  Increases are shown as percentage and indicated with a green arrow:
                  <v-icon
                    color="success"
                  >
                    {{ icons.mdiArrowUpBold }}
                  </v-icon>
                  <br>
                  <br>
                  There may be an opportunity to trade on the marketplace 💰.
                </p>
                <br>
                <v-flex class="text-center align-center">
                  <router-link to="/my-team" class="mb-4">
                    <v-btn
                      outlined
                      color="success"
                    >
                      View Team
                    </v-btn>
                  </router-link>
                </v-flex>
              </div>
            </div>
            <div v-else>
              <br>
              <h2
                class="font-weight-semibold text--primary d-flex align-center justify-center mb-2 pb-2 mt-2">
                <span>{{ claimHeader }}</span>
              </h2>
              <v-img
                :src="emptyImage"
                alt="no prizes"
                class="img-responsive text-center mx-auto mt-4 mb-0 pb-0"
                max-width="250"
              ></v-img>
              <div class="hidden-sm-and-down">
                <div class="text-center my-4">
                  <router-link to="/past-winnings">
                    <v-btn
                      outlined
                    >
                      View Past Prizes
                    </v-btn>
                  </router-link>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <audio
      ref="audio"
      src="@/assets/sounds/Bonus Coin Success.mp3"
      id="caudio"
    ></audio>
  </div>
</template>
<script>
import {mdiCheckCircleOutline, mdiArrowUpBold} from '@mdi/js'
import 'firebase/functions'
import firebase from 'firebase/app'
import dbFunctions from '@/db'
import tokenFunctions from '@/tokens'
import FighterCard from '@/views/components/FighterCard.vue'
import db from '@/db/init'

const silverKombatCoinTemplate = process.env.VUE_APP_SILVER_KOMBATCOIN_TEMPLATE

export default {
  components: {
    FighterCard,
  },
  setup() {
    return {
      icons: {
        mdiCheckCircleOutline,
        mdiArrowUpBold,
      },
    }
  },
  data() {
    return {
      user: this.$store.state.user,
      isMobile: false,
      claimHeader: 'Checking for prizes...',
      claimsLoaded: false,
      startClaim: false,
      claims: [],
      coinClaims: [],
      allcoinsClaims: false,
      fighterClaims: [],
      fighterClaimed: false,
      coinClaimed: true,
      coinClaiming: false,
      coinMinted: false,
      showCoinClaim: true,
      showFighterClaim: true,
      fighterClaiming: false,
      coinClaimText: 'Claim your KombatCoins',
      fighterClaimText: 'Claim Fighter Pack',
      fighterImage: require('@/assets/images/avatars/FantasyFighterCard.png'),
      emptyImage: require('@/assets/images/buy/empty-box.png'),
      rankedFighter: {},
    }
  },
  mounted() {
    db.collection('prizeClaims')
      .where('userUid', '==', this.user.uid)
      .where('claimed', '==', false)
      .onSnapshot(querySnapshot => {
        this.claims = []
        this.coinClaims = []
        querySnapshot.forEach(doc => {
          const prize = doc.data()
          prize.id = doc.id
          this.claims.push(prize)
        })
        for (let i = 0; i < this.claims.length; i += 1) {
          if (this.claims[i].claimType === 'skc') {
            this.coinClaims.push(this.claims[i])
          }
        }
        if (this.claims.length === 0) {
          this.claimHeader = 'You don\'t have any unclaimed prizes.'
        } else {
          this.claimHeader = 'Congrats you\'ve got rewards to claim!'
        }
        this.claimsLoaded = true
      })

    this.onResize()
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    hideFighter() {
      this.showFighterClaim = false
    },
    openPack() {
      this.startClaim = true
    },
    removeCard(index) {
      this.coinClaims.pop(this.coinClaims[index])
    },
    async claimCoins(item, index) {
      console.log('Start coin mint')
      const audio = document.getElementById('caudio')
      audio.currentTime = 0
      audio.volume = 0.1
      audio.play()
      this.coinClaiming = true
      const amount = item.silverCoins
      this.coinMinted = tokenFunctions.mintToken(this.user.profile.starDustPlayerId, silverKombatCoinTemplate, amount, this.user)
      dbFunctions.updateUsersProfile(this.user, {
        freeSilverKombatCoins: firebase.firestore.FieldValue.increment(amount),
        silverKombatCoinsLastTrans: amount,
        silverKombatCoins: firebase.firestore.FieldValue.increment(amount),
      })
        .then(() => {
          console.log('Minted coins saved')
          dbFunctions.prizeClaimed(item.id, {}, true)
          setTimeout(() => {
            this.coinClaiming = false
            this.coinClaimed = true
            if (this.claims[index]) {
              this.claims[index].claimed = true
            }
          }, 2000)
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style>

html {
  background-color: #28243d;
}

.opacity-1 {
  opacity: 1;
  color: #fff;
}

.cards-wrapper.mobile {
  justify-content: center;
  align-items: center;
  display: flex;
}

.mobile .cards-box {
  top: -60px;
}

.mobile .text-center.past-claims-btn {
  margin-top: -110px;
}

.cards-box {
  position: relative;
  margin: auto;
  width: 448px;
}

.cards-box .pb-12 {
  position: absolute;
}

.card .v-card {
  min-width: 400px;
  min-height: 476px;
  padding: 40px !important;
}

.card.cover {
  text-align: center;
  font-family: 'Black Ops One';
  font-size: 20px;
  vertical-align: middle;
  margin-top: 200px;
  margin-left: 19px;
  text-shadow: 1px 1px 2px black;
  color: #ddd;
  z-index: 9999;
  cursor: grab;

}

.card.plastic-wrap {
  height: 570px;
  opacity: 0.9 !important;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: saturate(117%) blur(12px);
  border: 2px solid #fff;
  width: 445px;
  border-radius: 8px;
  cursor: grab;
}

.cards-wrapper.closed {
  cursor: grab;
}

.card:hover {
  transform: rotate(
    0deg) !important;
  transition: transform 150ms ease-in-out 250ms;
}

.card.team .v-card.active {
  transform: rotate(
    -2deg);
}

.card.fighter .v-card.active {
  transform: rotate(
    2deg);
}

.card.coins .v-card.active {
  transform: rotate(
    -1deg);
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  cursor: grab;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 700 16px/1 Helvetica, Arial, sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  position: relative;
  z-index: 999;
  float: right;
  text-align: right;
  margin: -12px;
  cursor: grab;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
  box-shadow: 2px 2px 11px #333;
}

.opened .open-pack {
  animation: anim 1s linear forwards;
}

@keyframes anim {
  10% {
    border: none;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    border: none;
    opacity: 0;
    display: none !important;
    transform: translate3d(0, 50%, 0);
    overflow: hidden;

  }
  100% {
    border: none;
    opacity: 0;
    display: none !important;
    transform: translate3d(0, 140%, 0);
    visibility: hidden;
    height: 0;

  }
}

.mobile .cards-wrapper {
  margin: auto;
  display: block;
}

.cards-box .fighterCardWrapper {
  transform: scale(0.7);
  margin-top: -90px;
  margin-bottom: -80px;
  transition: transform 0.6s;
}

.cards-box .fighterCardWrapper:hover {
  transform: scale(1.1) !important;
  position: relative;
  z-index: 99999;
}

.cards-box .v-card.v-sheet.v-sheet--outlined.theme--dark {
  background: #312d4b !important;
}

.past-claims {
  position: relative;
  bottom: -595px;
}

@media only screen and (max-width: 600px) {
  .past-claims {
    position: relative;
    bottom: -380px;
  }
}
</style>
